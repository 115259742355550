<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline">Dashboard</router-link>
        <span class="text-gray-400"> / </span>
        <router-link to="/quotes" class="text-green hover:underline">Quotes</router-link>
        <span class="text-gray-400"> / </span>
        <span class="font-bold">{{ quote.number }}</span>
      </div>
    </div>
  </div>
  <div class="container mx-auto py-5" v-if="!loadingQuote">
    <div class="grid grid-cols-3 xl:grid-cols-4">
      <div class="col-span-3 grid grid-cols-3">
        <div class="shadow col-span-1 bg-gray-50 text-center px-4 pt-2 pb-10">
          <div class="text-xs flex text-gray-400">
            <span >Created {{ parseDate(quote.created_at) }}</span>
            <span class="text-right flex-grow">Updated {{ parseDate(quote.updated_at) }}</span>
          </div>
          <span class="block text-gray-400 mt-10">Quote</span>
          <h2 class="font-bold text-3xl">{{ quote.number }}</h2>
          <span class="block mt-10 font-bold">Status: {{ quote.status_text }}</span>
          <span class="block text-sm"><a href="#" class="text-green hover:underline" @click="showChangeStatusModal = true">Update Status</a></span>
        </div>
        <div class="shadow ml-5 bg-gray-100 col-span-2">
          <div class="text-sm">
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Name</span>
              <span class="w-9/12 font-bold">{{ quote.name }}</span>
            </div>
            <div class="px-5 py-2 flex">
              <span class="w-3/12">Created By</span>
              <span class="w-9/12 font-bold">{{ quote.customer.company_name ?? `${quote.customer.first_name} ${quote.customer.last_name}` }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Created By</span>
              <span class="w-9/12 font-bold">{{ quote.user.first_name }} {{ quote.user.last_name }}</span>
            </div>
            <div class="px-5 py-2 flex">
              <span class="w-3/12">Potential Value</span>
              <span class="w-9/12 font-bold" v-if="quote.potential_value != null">${{ Number(quote.potential_value).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Notes</span>
              <span class="w-9/12">{{ quote.notes }}</span>
            </div>
          </div>
        </div>
        <div class="shadow bg-gray-100 mt-5 col-span-3">
          <div class="px-5 py-2" :class="{'bg-gray-50':key % 2}" v-for="(part, key) in quote.parts" v-bind:key="part.id">
            <div class="flex w-full">
              <span class="mr-5 flex-grow">
                <span class="font-bold">{{ part.part_number }}<span v-if="part.cross_part_number">{{ part.cross_part_number }}</span></span>
                <span class="block text-xs text-gray-400 flex-grow">
                  Potential Quantity: {{ part.potential_quantity }}
                </span>
              </span>
              <span class="px-5 text-center" v-for="price in part.prices" v-bind:key="price.id">
                <span class="block text-sm">${{ price.price }}</span>
                <span class="text-xs text-gray-400">{{ price.quantity_min }} - {{ price.quantity_max }}</span>
              </span>
              
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-3 border-t border-gray-200 mt-5">
        <div class="shadow mt-5 bg-gray-100">
          <div class="px-5 py-2 bg-gray-50 border-b border-gray-200 flex w-full">
            <h2 class="font-bold">Comments</h2>
            <span class="text-sm flex-grow text-right mt-1"><a class="text-green hover:underline cursor-pointer" @click="showAddCommentModal = true">Add Comment</a></span>
          </div>
          <div class="px-2 pb-2" v-if="quote.comments.length > 0">
            <div class="mt-2 px-3 py-2 bg-gray-50 rounded border border-gray-200" v-for="comment in quote.comments" v-bind:key="comment.id">
              <div class="text-sm flex">
                <span class="inline-block font-bold">{{ comment.owner.first_name }} {{ comment.owner.last_name }}</span>
                <span class="inline-block ml-1 text-gray-400 text-xs flex-grow text-right">{{ parseDate(comment.created_at) }}</span>
              </div>
              <p class="mt-2 text-sm">{{comment.message}}</p>
            </div>
          </div>
          <div class="px-5 py-2 italic text-gray-400 text-sm" v-if="quote.comments.length == 0">
            No comments yet
          </div>
        </div>
      </div>
    </div>

    <vue-final-modal v-model="showChangeStatusModal" classes="flex justify-center items-center"
      content-class="relative flex flex-col max-h-full w-2/6 mx-4 border dark:border-gray-800 rounded bg-gray-100 dark:bg-gray-900">
      <h3 class="font-bold px-4 py-2 bg-gray-50">Update Sales Status</h3>
      <div class="px-4 py-2">
        <select class="p-2 rounded shadow w-full" v-model="status">
          <option value="0">Quoted</option>
          <option value="1">Sampled</option>
          <option value="2">Pricing Approved</option>
          <option value="3">Pending</option>
          <option value="4">Won</option>
          <option value="5">Lost</option>
          <option value="6">Request</option>
        </select>
      </div>
      <div class="px-4 py-2 bg-gray-50 text-right">
        <a class="text-sm mx-5 text-green hover:underline cursor-pointer" @click="showChangeStatusModal = false">Cancel</a>
        <button class="rounded bg-green text-white px-3 py-1 text-sm font-bold" @click="saveStatus()">Change Status</button>
      </div>
    </vue-final-modal>

    <vue-final-modal v-model="showAddCommentModal" classes="flex justify-center items-center"
      content-class="relative flex flex-col max-h-full w-2/6 mx-4 border dark:border-gray-800 rounded bg-gray-100 dark:bg-gray-900">
      <h3 class="font-bold px-4 py-2 bg-gray-50">Add Comment</h3>
      <div class="px-4 py-2">
        <textarea class="mt-2 w-full px-2 py-1 shadow" v-model="comment" ref="mes" rows="4"></textarea>
      </div>
      <div class="px-4 py-2 bg-gray-50 text-right">
        <a class="text-sm mx-5 text-green hover:underline cursor-pointer" @click="showAddCommentModal = false">Cancel</a>
        <button class="rounded bg-green text-white px-3 py-1 text-sm font-bold" @click="addComment()">Add Comment</button>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data: () => {
    return {
      id: null,
      showChangeStatusModal: false,
      showAddCommentModal: false,
      comment: "",
      loadingQuote: true,
      status: null,
    }
  },
  computed: {
    ...mapGetters({
        quote: 'quotes/details'
      }),
  },

  created: function () {
    this.loadQuote();
  },

  methods: {
    parseDate(date) {
			let d = new Date(date);
			return d.toLocaleDateString();
		},

    async loadQuote() {
      this.loadingQuote = true;
      await this.$store.dispatch('quotes/loadDetails', this.$route.params.id);
      document.title = 'MLE Rep Center | Quote '+ this.quote.number;
      this.status = this.quote.status;
      this.loadingQuote = false;
    },

    async addComment() {
      this.showAddCommentModal = false;
      await this.$store.dispatch('quotes/addComment', this.comment);
      this.comment = '';
    },

    async saveStatus() {
      this.showChangeStatusModal = false;
      await this.$store.dispatch('quotes/updateStatus', this.status);
      this.status = this.quote.sales_status;
    }
  }
}
</script>